import React, { useState, useEffect } from "react";
import { GeoJSON } from "react-leaflet";
import axios from "axios";

const GeoJSONLayers = ({ handleFeatureClick, layerVisibility }) => {
  const [giaothongLineData, setGiaothongLineData] = useState(null);
  const [thuyHeLineData, setThuyHeLineData] = useState(null);
  const [giaothongPgonData, setGiaothongPgonData] = useState(null);
  const [thuyHePgonData, setThuyHePgonData] = useState(null);
  const [tdnenData, setTdnenData] = useState(null);
  const [ctxdPolygonData, setCtxdPolygonData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          giaothongLineResponse,
          thuyHeLineResponse,
          giaothongPgonResponse,
          thuyHePgonResponse,
          tdnenResponse,
          ctxdPolygonResponse,
        ] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/giaothongLine`),
          axios.get(`${process.env.REACT_APP_API_URL}/thuyHeLine`),
          axios.get(`${process.env.REACT_APP_API_URL}/giaothongPgon`),
          axios.get(`${process.env.REACT_APP_API_URL}/thuyHePgon`),
          axios.get(`${process.env.REACT_APP_API_URL}/tdnen`),
          axios.get(`${process.env.REACT_APP_API_URL}/ctxdPolygon`),
        ]);

        setGiaothongLineData({
          type: "FeatureCollection",
          features: giaothongLineResponse.data,
        });
        setThuyHeLineData({
          type: "FeatureCollection",
          features: thuyHeLineResponse.data,
        });
        setGiaothongPgonData({
          type: "FeatureCollection",
          features: giaothongPgonResponse.data,
        });
        setThuyHePgonData({
          type: "FeatureCollection",
          features: thuyHePgonResponse.data,
        });
        setTdnenData({
          type: "FeatureCollection",
          features: tdnenResponse.data,
        });
        setCtxdPolygonData({
          type: "FeatureCollection",
          features: ctxdPolygonResponse.data,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const renderGeoJSONLayer = (data, style) => {
    return (
      <GeoJSON
        data={data}
        style={style}
        onEachFeature={(feature, layer) => {
          layer.bindPopup(createPopupContent(feature));
          layer.on("click", () => handleFeatureClick(feature));
        }}
      />
    );
  };

  return (
    <>
      {layerVisibility.giaothongLine &&
        giaothongLineData &&
        renderGeoJSONLayer(giaothongLineData, {})}
      {layerVisibility.thuyHeLine &&
        thuyHeLineData &&
        renderGeoJSONLayer(thuyHeLineData, {
          color: "blue",
          fillColor: "#0066ff",
          weight: 2,
          opacity: 3,
        })}
      {layerVisibility.giaothongPgon &&
        giaothongPgonData &&
        renderGeoJSONLayer(giaothongPgonData, {
          color: "black",
          fillColor: "#ffcccc",
          weight: 2,
          opacity: 1,
          fillOpacity: 5,
        })}
      {layerVisibility.thuyHePgon &&
        thuyHePgonData &&
        renderGeoJSONLayer(thuyHePgonData, {
          color: "blue",
          fillColor: "#0066ff",
          weight: 2,
          opacity: 1,
          fillOpacity: 5,
        })}
      {layerVisibility.tdnen &&
        tdnenData &&
        renderGeoJSONLayer(tdnenData, {
          color: "black",
          fillColor: "#ffff66",
          weight: 1,
          opacity: 1,
          fillOpacity: 3,
        })}
      {layerVisibility.ctxdPolygon &&
        ctxdPolygonData &&
        renderGeoJSONLayer(ctxdPolygonData, {
          color: "magenta",
          fillColor: "#ffa3dd",
          weight: 1,
          opacity: 1,
          fillOpacity: 0.6,
          dashArray: "5, 5",
        })}
    </>
  );
};

export default GeoJSONLayers;

const vietnameseFieldNames = {
  soto: "Số tờ",
  sothua: "Số thửa",
  dientich: "Diện tích",
  chusdd: "Chủ sử dụng đất",
  diachi: "Địa chỉ",
  ten: "Tên",
  loai: "Loại",
  tenduong: "Tên đường",
  ketcau: "Kết cấu",
  shape_leng: "Độ dài",
  shape_area: "Diện tích",
};

function createPopupContent(feature) {
  const properties = feature.properties;
  const selectedFields = [
    "soto",
    "sothua",
    "dientich",
    "chusdd",
    "diachi",
    "ten",
    "loai",
    "tenduong",
    "ketcau",
    "shape_leng",
    "shape_area",
  ];

  let content = "<div>";
  selectedFields.forEach((field) => {
    if (properties[field]) {
      const vietnameseFieldName = vietnameseFieldNames[field] || field;
      content += `<div><strong>${vietnameseFieldName}:</strong> ${properties[field]}</div>`;
    }
  });
  content += "</div>";
  return content;
}
