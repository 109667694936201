import React, { useState, useRef, useEffect } from "react";
import MapView from "./MapView";
import SearchSpatialForm from "../SearchSpatial/SearchSpatialForm";
import { calculateBounds } from "../Services/calculateBounds";
import { Button, Modal, Form, Nav } from "react-bootstrap";
import { FaLayerGroup, FaChartBar, FaChartPie, FaBars, FaTimes } from "react-icons/fa";
import axios from "axios";
import L from 'leaflet';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import "./IMap.css";
import FeatureInfo from "../FeatureInfo/FeatureInfo";
import SearchFreeText from "../SearchSpatial/SearchFreeText";
import StatisticsTable from "../StatisticsTable/StatisticsTable";
import ChartComponent from "../Chart/Chart";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const IMap = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [showLayersModal, setShowLayersModal] = useState(false);
  const [layerVisibility, setLayerVisibility] = useState({
    giaothongLine: true,
    thuyHeLine: true,
    giaothongPgon: true,
    thuyHePgon: true,
    tdnen: true,
    ctxdPolygon: true,
  });
  const [highlightedFeatures, setHighlightedFeatures] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState(null);

  const [showStatistics, setShowStatistics] = useState(false);
  const [statisticData, setStatisticData] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [chartData, setChartData] = useState({ labels: [], values: [] });

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const toggleLayer = (layerName) => {
    setLayerVisibility((prev) => {
      const newVisibility = !prev[layerName];

      // Show toast notification
      if (newVisibility) {
        toast.success(`Đã mở lớp ${getLayerLabel(layerName)}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.info(`Đã tắt lớp ${getLayerLabel(layerName)}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

      return {
        ...prev,
        [layerName]: newVisibility,
      };
    });
  };

  const mapRef = useRef(null);
  const controlsRef = useRef({
    scale: null,
    zoom: null,
    fullscreen: null
  });

  const handleFeatureClick = async (feature) => {
    console.log("Feature clicked:", feature);
    const { soto, sothua } = feature.properties;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/fetch-parcel-details`,
        {
          params: { soto, sothua },
        }
      );
      const responseData = response.data;
      console.log(responseData);

      if (responseData) {
        setSelectedFeature(responseData);
      } else {
        console.log("Không tìm thấy thửa đất phù hợp.");
        setSelectedFeature(null);
      }
    } catch (error) {
      console.error("Lỗi khi tìm kiếm chi tiết thửa đất:", error);
      setSelectedFeature(null);
    }
  };

  const handleFreeTextSearch = async (query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/search-parcel`,
        {
          params: { query },
        }
      );
      const responseData = response.data;

      if (responseData && responseData.features.length > 0) {
        const bounds = calculateBounds(responseData.features);

        if (mapRef.current) {
          mapRef.current.fitBounds(bounds, { maxZoom: 20 });
        }

        setHighlightedFeatures(responseData.features);
      } else {
        console.log("Không tìm thấy thửa đất phù hợp.");
        setHighlightedFeatures([]);
      }
    } catch (error) {
      console.error("Lỗi khi tìm kiếm không gian:", error);
    }
  };

  const handleSpatialSearch = async (soto, sothua) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/search-spatial`,
        {
          params: { soto, sothua },
        }
      );
      const responseData = response.data;
      console.log(response.data);

      if (responseData && responseData.features.length > 0) {
        const bounds = calculateBounds(responseData.features);
        console.log(bounds);

        if (mapRef.current) {
          mapRef.current.fitBounds(bounds, { maxzoom: 16 });
        }

        setHighlightedFeatures(responseData.features);
      } else {
        console.log("Không tìm thấy thửa đất phù hợp.");
        setHighlightedFeatures([]);
      }
    } catch (error) {
      console.error("Lỗi khi tìm kiếm không gian:", error);
    }
  };

  const clearHighlights = () => {
    setHighlightedFeatures([]);
  };

  const handleStatisticClick = async () => {
    if (showStatistics) {
      setShowStatistics(false);
      setShowChart(false);
    } else {
      try {
        const [
          gpxdResponse,
          khongGPXDResponse,
          soNhaResponse,
          khongSoNhaResponse,
        ] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/thua-dat-nen-co-gpxd`),
          axios.get(`${process.env.REACT_APP_API_URL}/thua-dat-nen-chua-co-gpxd`),
          axios.get(`${process.env.REACT_APP_API_URL}/thua-dat-nen-co-so-nha`),
          axios.get(`${process.env.REACT_APP_API_URL}/thua-dat-nen-khong-co-so-nha`),
        ]);

        const thuaDatCoGPXD = gpxdResponse.data.count;
        const thuaDatKhongGPXD = khongGPXDResponse.data.count;
        const thuaDatCoSoNha = soNhaResponse.data.count;
        const thuaDatKhongCoSoNha = khongSoNhaResponse.data.count;

        const statisticData = [
          { label: "Thửa đất có GPXD", value: thuaDatCoGPXD },
          { label: "Thửa đất không có GPXD", value: thuaDatKhongGPXD },
          { label: "Thửa đất có số nhà", value: thuaDatCoSoNha },
          { label: "Thửa đất không có số nhà", value: thuaDatKhongCoSoNha },
        ];

        const chartLabels = statisticData.map((item) => item.label);
        const chartValues = statisticData.map((item) => item.value);
        setChartData({ labels: chartLabels, values: chartValues });

        setStatisticData(statisticData);
        setShowStatistics(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleShowChartClick = () => {
    setShowChart(!showChart);
  };

  useEffect(() => {
    if (mapRef.current && !controlsRef.current.scale) {
      const map = mapRef.current;

      // Add scale control
      controlsRef.current.scale = L.control.scale().addTo(map);

      // Add zoom control
      controlsRef.current.zoom = L.control.zoom({
        position: 'bottomright'
      }).addTo(map);

      // Add fullscreen control
      controlsRef.current.fullscreen = L.control.fullscreen({
        position: 'topleft',
        title: 'Toàn màn hình',
        titleCancel: 'Thoát toàn màn hình',
        content: null,
        forceSeparateButton: true,
        forcePseudoFullscreen: true,
        fullscreenElement: false
      }).addTo(map);
    }

    if (mapRef.current) {
      mapRef.current.invalidateSize();
    }
  }, []);

  return (
    <div className="map-container d-flex flex-column vh-100">
      <ToastContainer />
      <div className="map-content flex-grow-1 d-flex">
        <Nav className={`flex-column sidebar bg-light ${sidebarCollapsed ? 'collapsed' : ''}`}>
          <Button
            onClick={toggleSidebar}
            className="toggle-sidebar-btn btn-sm align-self-end m-2"
            variant="outline-secondary"
          >
            <FaBars />
          </Button>
          {!sidebarCollapsed && (
            <div className="sidebar-content p-3">
              <h5 className="mb-3">Công cụ</h5>
              <Button
                onClick={() => setShowLayersModal(true)}
                className="mb-2 d-flex align-items-center"
                variant="outline-primary"
                size="sm"
              >
                <FaLayerGroup className="me-2" /> Lớp bản đồ
              </Button>
              <SearchFreeText
                onSearch={handleFreeTextSearch}
                clearHighlights={clearHighlights}
              />
              <SearchSpatialForm
                onSearch={handleSpatialSearch}
                clearHighlights={clearHighlights}
              />
              <Button
                onClick={handleStatisticClick}
                className="mt-2 d-flex align-items-center"
                variant="outline-info"
                size="sm"
              >
                <FaChartBar className="me-2" /> {showStatistics ? 'Ẩn' : 'Hiện'} Thống kê
              </Button>
              <Button
                onClick={handleShowChartClick}
                className="mt-2 d-flex align-items-center"
                variant="outline-success"
                size="sm"
              >
                <FaChartPie className="me-2" /> {showChart ? 'Ẩn' : 'Hiện'} Biểu đồ
              </Button>
            </div>
          )}
        </Nav>
        <div className="map-wrapper flex-grow-1">
          <MapView
            layerVisibility={layerVisibility}
            handleFeatureClick={handleFeatureClick}
            mapRef={mapRef}
            highlightedFeatures={highlightedFeatures}
          />
          {selectedFeature && (
            <FeatureInfo
              feature={selectedFeature}
              onClose={() => setSelectedFeature(null)}
            />
          )}
          {showStatistics && <StatisticsTable statisticData={statisticData} />}
          {showChart && <ChartComponent data={chartData} />}
        </div>
      </div>
      <Modal show={showLayersModal} onHide={() => setShowLayersModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Điều khiển lớp bản đồ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.entries(layerVisibility).map(([layerName, isVisible]) => (
            <Form.Check
              key={layerName}
              type="checkbox"
              id={layerName}
              label={getLayerLabel(layerName)}
              checked={isVisible}
              onChange={() => toggleLayer(layerName)}
            />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLayersModal(false)}>
            <FaTimes /> Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const getLayerLabel = (layerName) => {
  const labels = {
    giaothongLine: "Giao thông (đường)",
    thuyHeLine: "Thủy hệ (đường)",
    giaothongPgon: "Giao thông (vùng)",
    thuyHePgon: "Thủy hệ (vùng)",
    tdnen: "Thửa đất nền",
    ctxdPolygon: "Công trình xây dựng khác(nhà)",
  };
  return labels[layerName] || layerName;
};

export default IMap;
