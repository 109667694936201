import React from "react";
import PropTypes from 'prop-types'


import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { useAuth } from '../../AuthContext';

const Header = ({ button }) => {
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    localStorage.removeItem('token');
    navigate('/');
  };

  return (
    <Navbar bg="light" expand="lg" className="mb-3">
      <Container>
        <Navbar.Brand as={Link} to="/">GIS Quận 8</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Trang chủ</Nav.Link>
            <Nav.Link as={Link} to="/about">Giới thiệu</Nav.Link>
            {isLoggedIn && <Nav.Link as={Link} to="/map">Bản đồ</Nav.Link>}
          </Nav>
          <Nav>
            {isLoggedIn ? (
              <>
                <Button variant="outline-primary" onClick={handleLogout}>Đăng xuất</Button>

              </>
            ) : (
              <Button variant="primary" as={Link} to="/login">Đăng nhập</Button>
            )}
          </Nav>
          <Nav>
            {isLoggedIn ? (button) : (null)}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
Header.propTypes = {
  button: PropTypes.node,
}
export default Header;
