import React from "react";
import { Card, Button } from "react-bootstrap";
import "./FeatureInfo.css";

const FeatureInfo = ({ feature, onClose }) => {
  return (
    <div className="feature-info">
      <Card>
        <Card.Header>
          <Button variant="danger" onClick={onClose}>
            x
          </Button>
        </Card.Header>
        <Card.Body>
          <Card.Title>Thông tin thửa đất</Card.Title>
          <Card.Text>
            <strong>ID:</strong> {feature.properties.id} <br />
            <strong>Mã thửa đất:</strong> {feature.properties.mathuadat} <br />
            <strong>Số tờ:</strong> {feature.properties.soto} <br />
            <strong>Số thửa:</strong> {feature.properties.sothua} <br />
            <strong>Diện tích:</strong> {feature.properties.dientich} <br />
            <strong>Loại đất:</strong> {feature.properties.loaidat} <br />
            <strong>Chủ sử dụng đất:</strong> {feature.properties.chusdd} <br />
            <strong>Địa chỉ:</strong> {feature.properties.diachi} <br />
            <br />
          </Card.Text>
          <Card.Title>Thông tin hồ sơ giấy phép xây dựng</Card.Title>
          <Card.Text>
            <strong>Số giấy phép xây dựng:</strong> {feature.properties.sogpxd}{" "}
            <br />
            <strong>Ngày cấp:</strong> {feature.properties.ngaycapgpx} <br />
            <strong>Diện tích theo giấy phép:</strong>{" "}
            {feature.properties.dientichta} <br />
            <strong>Tổng diện tích xây:</strong> {feature.properties.tongdienti}{" "}
            <br />
            <strong>Số tầng:</strong> {feature.properties.sotang} <br />
            <strong>Mật độ xây dựng:</strong> {feature.properties.matdoxd}{" "}

            <br />
            <strong>Cốt nền xây dựng:</strong> {feature.properties.cotnenxdct} <br />
            <strong>Kết cấu công trình:</strong> {feature.properties.ketcauct}{" "}

            <br />
            <strong>Màu sắc công trình:</strong> {feature.properties.mausaccongtrinh} <br />
            <strong>Chiều cao công trình:</strong> {feature.properties.chieucaoct}{" "}
            <br />
          </Card.Text>
          <Card.Title>Thông tin số nhà</Card.Title>
          <Card.Text>
            <strong>Mã hồ sơ:</strong> {feature.properties.mahs} <br />
            <strong>Tên hồ sơ:</strong> {feature.properties.tenhoso} <br />
            <strong>Tên đường:</strong> {feature.properties.tenduong} <br />
            <strong>Số nhà cấp:</strong> {feature.properties.sonhacap} <br />
            <strong>Số nhà mới:</strong> {feature.properties.sonhamoi} <br />
            <strong>Quyết định cấp số nhà:</strong>{" "}
            {feature.properties.qdcapsonha} <br />
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default FeatureInfo;
