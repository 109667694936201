import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { Card, Button } from "react-bootstrap";

const ChartComponent = ({ data, onClose }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current !== null) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext("2d");
    chartInstance.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: data.labels,
        datasets: [
          {
            label: data.label,
            data: data.values,
            backgroundColor: getFixedColors(data.values.length), // Sử dụng một hàm để sinh màu ngẫu nhiên
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        indexAxis: "y", // Chuyển dữ liệu từ trục x sang trục y để hiển thị cột nhỏ lại
        elements: {
          bar: {
            barPercentage: 0.6, // Chiều rộng của cột
            categoryPercentage: 0.8, // Khoảng cách giữa các cột
          },
        },
      },
    });
  }, [data]);

  const getFixedColors = (count) => {
    // Mảng các màu cố định
    const fixedColors = [
      "rgba(255, 99, 132, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(255, 206, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(153, 102, 255, 0.2)",
      "rgba(255, 159, 64, 0.2)",
      // Thêm các màu khác nếu cần
    ];

    // Trả về mảng màu cố định tương ứng với số lượng dữ liệu
    return fixedColors.slice(0, count);
  };

  return (
    <Card>
      <Card.Header>
        <Button variant="danger" onClick={onClose}>
          x
        </Button>
      </Card.Header>
      <Card.Body>
        <canvas ref={chartRef} />
      </Card.Body>
    </Card>
  );
};

export default ChartComponent;
