import React from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
// import "./MapView.css";
import GeoJSONLayers from "../Services/MapService";

const MapView = ({
  layerVisibility,
  handleFeatureClick,
  mapRef,
  highlightedFeatures,
}) => {
  const highlightStyle = {
    color: "#ff7800",
    weight: 5,
    opacity: 1,
    fillOpacity: 0.7,
  };

  return (
    <MapContainer
      center={[10.750075026044847, 106.67830343862978]}
      zoom={16}
      scrollWheelZoom={true}
      maxZoom={22}
      minZoom={12}
      style={{ height: "100%", width: "100%" }}
      ref={mapRef}
    >
      <TileLayer
        attribution=""
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <GeoJSONLayers
        handleFeatureClick={handleFeatureClick}
        layerVisibility={layerVisibility}
      />
      {highlightedFeatures.map((feature, index) => (
        <GeoJSON
          key={index}
          data={feature}
          style={highlightStyle}
          onEachFeature={(feature, layer) => {
            layer.on({
              click: () => handleFeatureClick(feature),
            });
          }}
        />
      ))}
    </MapContainer>
  );
};

export default MapView;
