// Trong SearchSpatialForm.js
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchFreeText = ({ onSearch }) => {
  const [query, setQuery] = useState("");

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (query.trim() !== "") {
      await onSearch(query);
      setQuery(""); // Xóa nội dung trong ô nhập liệu sau khi tìm kiếm
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <input
        type="text"
        value={query}
        onChange={handleChange}
        placeholder="Nhập thông tin tìm kiếm tự do"
      />
      <Button type="submit" className="btn-primary ml-2">
        <FontAwesomeIcon icon={faSearch} />
      </Button>
    </Form>
  );
};

export default SearchFreeText;
