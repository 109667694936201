import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import './Login.css';


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const urlLogin = `${process.env.REACT_APP_API_URL}/users/login`;
        try {
            const response = await axios.post(`${urlLogin}`, {
                username,
                password,
            });
            localStorage.setItem('token', response.data.token);
            login();
            navigate('/map');
        } catch (error) {
            setError('Không đủ quyền truy cập trang web này!');
        }
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Form onSubmit={handleSubmit} className="p-4 border rounded shadow">
                        <h2 className="text-center mb-4">Đăng nhập</h2>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form.Group className="mb-3" controlId="username">
                            <Form.Label>Tài Khoản:</Form.Label>
                            <Form.Control
                                type="text"
                                value={username}
                                autocomplete="username"
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Mật Khẩu:</Form.Label>
                            <Form.Control
                                type="password"
                                value={password}
                                autocomplete="current-password"
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="w-100">
                            Đăng nhập
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
