import React, { useState } from 'react';
import { Dropdown, Spinner, Toast } from 'react-bootstrap';
import { FaFileExcel } from 'react-icons/fa';

const ExportButton = () => {
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const handleExport = async (type) => {
        setLoading(true);
        try {
            let url;
            if (type === "hoSoGiayPhep") {
                url = `${process.env.REACT_APP_API_URL}/export-excel/hoSoGiayPhepXayDung`;
            } else if (type === "soNha") {
                url = `${process.env.REACT_APP_API_URL}/export-excel/so-nha`;
            }

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = type === "hoSoGiayPhep" ? 'HSGPXD.xlsx' : 'so_nha.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(blobUrl);

            setToastMessage('Xuất file thành công!');
        } catch (error) {
            console.error("Error exporting data:", error);
            setToastMessage('Có lỗi xảy ra khi xuất file.');
        } finally {
            setLoading(false);
            setShowToast(true);
        }
    };

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {loading ? <Spinner animation="border" size="sm" /> : <FaFileExcel style={{ marginRight: '5px' }} />}
                    Xuất Excel
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleExport("hoSoGiayPhep")}>
                        Hồ Sơ Giấy Phép Xây Dựng
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleExport("soNha")}>
                        Số Nhà
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
        </>
    );
};

export default ExportButton;
