import React from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./App.css";

import { AuthProvider, useAuth } from './AuthContext';
import HomePage from './components/HomePage/HomePage';
import AboutPage from './components/AboutPage/AboutPage';
import IMap from './components/Map/IMap';
import Login from './components/Login/Login';
import Header from './components/Layout/Header';
import ExportButton from "./components/ExportButton/ExportButton";

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? children : <Navigate to="/login" />;
};

function App() {
  const location = useLocation();

  return (
    <AuthProvider>
      <div className="App">
        {location.pathname !== '/login' && <Header button={<ExportButton />} />}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/map" element={
            <ProtectedRoute>
              <IMap />
            </ProtectedRoute>
          } />
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
    </AuthProvider>
  );
}


export default App;
