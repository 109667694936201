import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const AboutPage = () => {
    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={8}>
                    <h1>Giới thiệu về Ứng dụng GIS của chúng tôi</h1>
                    <p>
                        Ứng dụng GIS của chúng tôi được thiết kế để cung cấp các công cụ bản đồ và phân tích không gian mạnh mẽ.
                        Nó cho phép người dùng trực quan hóa, phân tích và diễn giải dữ liệu địa lý để hiểu
                        các mô hình và mối quan hệ.
                    </p>
                    <h2>Các tính năng chính:</h2>
                    <ul>
                        <li>Bản đồ tương tác</li>
                        <li>Công cụ phân tích không gian</li>
                        <li>Trực quan hóa dữ liệu</li>
                        <li>Quản lý lớp bản đồ tùy chỉnh</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
};

export default AboutPage;
