// StatisticsTable.js
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./StatisticsTable.css"; // Import CSS file for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindowMinimize,
  faWindowMaximize,
} from "@fortawesome/free-solid-svg-icons";

const StatisticsTable = ({ statisticData }) => {
  const [isMinimized, setIsMinimized] = useState(false);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <div className={`statistics-table ${isMinimized ? "minimized" : ""}`}>
      <Button
        onClick={toggleMinimize}
        className="minimize-button"
        variant="danger"
      >
        {isMinimized ? (
          <FontAwesomeIcon icon={faWindowMaximize} />
        ) : (
          <FontAwesomeIcon icon={faWindowMinimize} />
        )}
      </Button>
      {!isMinimized && (
        <table>
          <thead>
            <tr>
              <th>Thống kê</th>
              <th>Giá trị</th>
            </tr>
          </thead>
          <tbody>
            {statisticData.map((item, index) => (
              <tr key={index}>
                <td>{item.label}</td>
                <td>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default StatisticsTable;
