import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';

const HomePage = () => {
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <h1>Chào mừng đến với ứng dụng GIS Quận 8</h1>
          <p className="lead">
            Khám phá và phân tích dữ liệu địa lý với các công cụ lập bản đồ mạnh mẽ của chúng tôi.
            Explore and analyze geographical data with our powerful mapping tools.
          </p>
          <Link to="/map">
            <Button variant="primary" size="lg" className="mt-3">
              Đi đến Bản đồ
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default HomePage;
