import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

const SearchSpatialForm = ({ onSearch, clearHighlights }) => {
  const [soto, setSoto] = useState("");
  const [sothua, setSothua] = useState("");

  const handleSearch = () => {
    if (soto && sothua) {
      clearHighlights(); // Clear old highlights
      onSearch(soto, sothua);
      setSoto(""); // Reset form fields
      setSothua("");
    }
  };

  return (
    <>
      <Form>
        <Form.Group controlId="soto">
          <Form.Label>Số tờ</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nhập số tờ"
            value={soto}
            onChange={(e) => setSoto(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="sothua">
          <Form.Label>Số thửa</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nhập số thửa"
            value={sothua}
            onChange={(e) => setSothua(e.target.value)}
          />
        </Form.Group>
        <Button className="mt-2" variant="primary" onClick={handleSearch}>
          Tìm kiếm
        </Button>
      </Form>
    </>
  );
};

export default SearchSpatialForm;
