import * as turf from "@turf/turf";

export const calculateBounds = (features) => {
  const bbox = turf.bbox({
    type: "FeatureCollection",
    features: features,
  });

  // Trả về bounds dưới dạng [[southWestLat, southWestLng], [northEastLat, northEastLng]]
  return [
    [bbox[1], bbox[0]], // SouthWest
    [bbox[3], bbox[2]], // NorthEast
  ];
};
